@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root, #root-cached {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior-y: none;
}

body {
  font-family:
    "Source Sans 3",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

.JsxText {
  color: #5c6773;
}

.JsxSelfClosingElement,
.JsxOpeningElement,
.JsxClosingElement,
.tagName-of-JsxOpeningElement,
.tagName-of-JsxClosingElement,
.tagName-of-JsxSelfClosingElement {
  color: #41a6d9;
}

.name-of-JsxAttribute {
  color: #f08c36;
}

.name-of-PropertyAssignment {
  color: #86b300;
}

.name-of-PropertyAccessExpression {
  color: #f08c36;
}
